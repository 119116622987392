// src/Elements/Structures/CTAs/TwoColumnCtaStyles.js

export const defaultCtaStyles = {
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  },
  ctaTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  ctaDescription: {
    fontSize: '1rem',
    marginBottom: '24px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '12px',
    marginTop: '24px',
  },
  primaryButton: {
    padding: '12px 24px',
    backgroundColor: '#1a1aff',
    color: '#ffffff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  secondaryButton: {
    padding: '12px 24px',
    backgroundColor: 'transparent',
    color: '#1a1aff',
    border: '2px solid #1a1aff',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};


export const ctaOneStyles = {
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    backgroundColor: '#f8f8f8',
    textAlign: 'center',
    gap:'2rem',

  },
  ctaTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  ctaDescription: {
    fontSize: '1rem',
    marginBottom: '24px',
  },
  primaryButton: {
    padding: '12px 24px',
    backgroundColor: '#1a1aff',
    color: '#ffffff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export const ctaTwoStyles = {
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    backgroundColor: '#ffffff',
    textAlign: 'center',
  },
  ctaTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '12px',
    marginTop: '24px',
  },
  primaryButton: {
    padding: '12px 24px',
    backgroundColor: '#1a1aff',
    color: '#ffffff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  secondaryButton: {
    padding: '12px 24px',
    backgroundColor: 'transparent',
    color: '#1a1aff',
    border: '2px solid #1a1aff',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};
